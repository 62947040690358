import React from "react";
import { Link } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@system42/core";
import { ButtonPrimaryLarge } from "../system42";
import Layout from "../layouts/layout";

import { HtmlContent } from "../components/HtmlContent";

import * as styles from "./styles/404.module.css";
import * as helperStyles from "./helpers.module.css";

const NotFoundPage = () => (
  <Layout title={"Page Not Found"}>
    <article>
      <header className={styles.header}>
        <h1 className={styles.title}>404</h1>

        <div>
          <p className={styles.sorry}>Sorry, we can’t find this page.</p>
          <ButtonPrimaryLarge customTag={Link} to={"/"}>
            Go to homepage
          </ButtonPrimaryLarge>
        </div>

        <div className={cn(styles.funnyUser, styles.funnyUser_1)} aria-hidden>
          <span className={styles.funnyUserName}>Monica</span>
          <span>Where is this page?</span>
        </div>
        <div className={cn(styles.funnyUser, styles.funnyUser_2)} aria-hidden>
          <span className={styles.funnyUserName}>Sarah</span>
          <span>How did I get here?</span>
        </div>
        <div className={cn(styles.funnyUser, styles.funnyUser_3)} aria-hidden>
          <span className={styles.funnyUserName}>Matt</span>
          <span>This button looks helpful.</span>
        </div>
      </header>

      <div className={cn(helperStyles.container, helperStyles.spacingMd)}>
        <HtmlContent className={helperStyles.widthHtmlContent}>
          <h2>What could have caused this?</h2>
          <ul>
            <li>The page was removed by us.</li>
            <li>
              The link you just clicked had a typing mistake or is outdated.
            </li>
            <li>
              Or you might have accidentally typed a wrong URL in the address
              bar.
            </li>
          </ul>
        </HtmlContent>
      </div>
    </article>
  </Layout>
);

export default NotFoundPage;
